import React from 'react';
import logo from './logo.svg';
import './App.css';
import './freelancer.css';

function App() {
  return (
    <div className="App">
      <head>

        <meta charset="utf-8"/>
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no"/>
        <meta name="description" content=""/>
        <meta name="author" content=""/>

        <title>MLHD | Vlad Borisov</title>

        <link href="vendor/fontawesome-free/css/all.min.css" rel="stylesheet" type="text/css"/>
        <link href="https://fonts.googleapis.com/css?family=Montserrat:400,700" rel="stylesheet" type="text/css"/>
        <link href="https://fonts.googleapis.com/css?family=Lato:400,700,400italic,700italic" rel="stylesheet" type="text/css"/>

        <link href="freelancer.css" rel="stylesheet"/>

      </head>

      <body id="page-top">

        <nav class="navbar navbar-expand-lg bg-secondary text-uppercase fixed-top" id="mainNav">
          <div class="container">
            <a class="navbar-brand js-scroll-trigger" href="#page-top">Vlad Borisov</a>
            <button class="navbar-toggler navbar-toggler-right text-uppercase font-weight-bold bg-primary text-white rounded" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
              Menu
              <i class="fas fa-bars"></i>
            </button>
            <div class="collapse navbar-collapse" id="navbarResponsive">
              <ul class="navbar-nav ml-auto">
                <li class="nav-item mx-0 mx-lg-1">
                  <a class="nav-link py-3 px-0 px-lg-3 rounded js-scroll-trigger" href="#about">About MLHD</a>
                </li>
                <li class="nav-item mx-0 mx-lg-1">
                  <a class="nav-link py-3 px-0 px-lg-3 rounded js-scroll-trigger" href="https://www.vladborisov.com">Back Home</a>
                </li>
              </ul>
            </div>
          </div>
        </nav>

        <header class="masthead bg-primary text-white text-center">
          <div class="container d-flex align-items-center flex-column">

            <h1 class="masthead-heading text-uppercase mb-0">MLHD</h1>

            <div class="divider-custom divider-light">
              <div class="divider-custom-line"></div>
              <div class="divider-custom-icon">
                <i class="fas fa-motorcycle"></i>
              </div>
              <div class="divider-custom-line"></div>
            </div>

            <p class="masthead-subheading font-weight-light mb-0">A Machine Learning Handwriting Digitizer</p>

            <div class="col-lg"><br/><br/>hello i am the mlhd canvas interactive element app fella </div>

          </div>
        </header>

        <section class="page-section mb-0" id="about">
          <div class="container">

            <h2 class="page-section-heading text-center text-uppercase text-secondary">About</h2>

            <div class="divider-custom ">
              <div class="divider-custom-line"></div>
              <div class="divider-custom-icon">
                <i class="fas fa-motorcycle"></i>
              </div>
              <div class="divider-custom-line"></div>
            </div>

            <div class="row">
              <div class="col-lg">
                <p>MLHD is built off of a CNN model utilizing convolutional & pooling layers and trained on E-MNIST data. The model itself is set up mostly in Python; Tensorflow 2 handles the ML backend w/ tight Keras integration, and JS & React handle user interaction.
                </p>
              </div>
            </div>
          </div>
        </section>


        <section class="copyright py-4 text-center text-white">
          <div class="container">
            <small>Copyright &copy; Vladislav Borisov 2020</small>
          </div>
        </section>

        <div class="scroll-to-top d-lg-none position-fixed ">
          <a class="js-scroll-trigger d-block text-center text-white rounded" href="#page-top">
            <i class="fa fa-chevron-up"></i>
          </a>
        </div>


        <script src="vendor/jquery/jquery.min.js"></script>
        <script src="vendor/bootstrap/js/bootstrap.bundle.min.js"></script>

        <script src="vendor/jquery-easing/jquery.easing.min.js"></script>

        <script src="js/jqBootstrapValidation.js"></script>
        <script src="js/contact_me.js"></script>

        <script src="js/freelancer.min.js"></script>



      </body>

    </div>
  );
}

export default App;